import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import '@mdi/font/css/materialdesignicons.css'

Vue.use(Vuetify);

export default new Vuetify({
    icons: {
        iconfont: 'mdi',
    },
    theme: {
        options: {
            customProperties: true,
        },
        themes: {
            light: {
                primary: '#1F4643',
                secondary: '#839997',
                accent: '#E4C59B',
                default: '#E8ECEC',
                bglight: '#F8FAFA',
                bglight2: '#eef1f1',
                ptext: '#333333',
                stext: '#5B5B5B',
                ltext: '#b1b1b1',
                error: '#FF5252',
                info: '#2196F3',
                success: '#4CAF50',
                warning: '#FFC107'
            },
        },
    },
});
