import Vue from "vue";
import VueRouter from "vue-router";
import store from "@/store";

Vue.use(VueRouter);

const routes = [
    {
        path: "/",
        redirect: "/signup"
    },
    {
        path: "/signup",
        name: "signup",
        component: () => import(/* webpackChunkName: "signup" */ "../views/auth/Index")
    },
    {
        path: "/login",
        name: "login",
        component: () => import(/* webpackChunkName: "login" */ "../views/auth/Index")
    },
    {
        path: "/terms-and-conditions",
        name: "terms-and-conditions",
        component: () => import(/* webpackChunkName: "terms-and-conditions" */ "../views/terms-and-conditions"),
        meta: { requiresAuth: false }
    },
    {
        path: "/privacy-policy",
        name: "privacy-policy",
        component: () => import(/* webpackChunkName: "privacy-policy" */ "../views/privacy-policy"),
        meta: { requiresAuth: false }
    },
    {
        path: "/dashboard",
        name: "dashboard",
        component: () => import(/* webpackChunkName: "dashboard" */ "../views/dashboard/Index"),
        meta: { requiresAuth: true }
    },
    {
        path: "/settings",
        name: "settings",
        component: () => import(/* webpackChunkName: "settings" */ "../views/settings/Index"),
        meta: { requiresAuth: true }
    },
    {
        path: "/locations",
        name: "locations",
        component: () => import(/* webpackChunkName: "locations" */ "../views/vendors/Index"),
        meta: { requiresAuth: true }
    },
    {
        path: "/bookings",
        name: "bookings",
        component: () => import(/* webpackChunkName: "bookings" */ "../views/bookings/Index"),
        meta: { requiresAuth: true }
    },
    {
        path: "/hotels",
        name: "hotels",
        component: () => import(/* webpackChunkName: "hotels" */ "../views/vendors/Index"),
        meta: { requiresAuth: true },
        children: []
    },
    {
        path: "/payments",
        name: "payments",
        component: () => import(/* webpackChunkName: "payments" */ "../views/payments/Index"),
        meta: { requiresAuth: true }
    },
    {
        path: "/promotions",
        name: "promotions",
        component: () => import(/* webpackChunkName: "promotions" */ "../views/promotions/Index"),
        meta: { requiresAuth: true }
    },
    {
        path: "/plans",
        name: "plans",
        component: () => import(/*webpackChunkName: "plans" */ "../views/plans/Index"),
        meta: { requiresAuth: true }
    },
    {
        path: "/coupons",
        name: "coupons",
        component: () => import(/*webpackChunkName: "coupons" */ "../views/coupons/Index"),
        meta: { requiresAuth: true }
    },
    {
        path: "/requests",
        name: "requests",
        component: () => import(/* webpackChunkName: "requests" */ "../views/requests/Index"),
        meta: { requiresAuth: true }
    },
    {
        path: "/subscriptions",
        name: "subscriptions",
        component: () => import(/* webpackChunkName: "subscriptions" */ "../views/subscriptions/Index"),
        meta: { requiresAuth: true }
    },
    {
        path: "/billing-center",
        name: "billing-center",
        component: () => import(/* webpackChunkName: "billing-center" */ "../views/invoices/InvoiceManagement"),
        meta: { requiresAuth: true }
    }
    /*{
        path: '/search',
        name: 'search',
        component: () => import(/'../views/enduser/searchlistings/Search'),
        meta: {requiresAuth: true }
    },*/
];

const router = new VueRouter({
    routes,
    linkActiveClass: "active"
});

router.beforeEach(async (to, from, next) => {
    if (to.matched.some(record => record.meta.requiresAuth) && !store.getters.isAuthenticated) {
        next({ name: "login" });
    } else {
        next();
    }
});

export default router;
