import Vue from "vue";
import imageCompression from "browser-image-compression";

Vue.filter("ucfirst", function (string = "") {
    return string.charAt(0).toUpperCase() + string.slice(1);
});
Vue.filter("applyDecimal", function (amount = null) {
    amount = parseFloat(amount);
    let decimal = amount.toFixed(2);
    return decimal.replace(".", ",");
});

Vue.filter("currency", function (amount = 0, currency = "EUR", locale = "de-DE") {
    return Intl.NumberFormat(locale, {
        style: "currency",
        currency: currency,
    }).format(amount);
});

Vue.filter("statusColor", function (status = "") {
    switch (status) {
        case "pending":
            return "error";
        case "active":
        case "paid":
            return "success";
        case "draft":
            return "";
        case "cancelled":
            return "error";
        case "inactive":
            return "error";
        case "hide":
            return "warning";
        default:
            return "warning";
    }
});

Vue.filter("defaultPlanColor", function (is_default = 0) {
    switch (is_default) {
        case 1:
            return "green";
        case 0:
            return "secondary";
        default:
            return "warning";
    }
});

Vue.filter("commaSeparator", function (value = "") {
    if (value) {
        value = value % 1 === 0 ? parseInt(value) : parseFloat(value).toFixed(2);
        return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }
    return value;
});

Vue.filter("status", function (status = "") {
    switch (status) {
        case "paid":
            return "Paid";
        case "pending":
            return "Pending";
        case "active":
            return "Active";
        case "draft":
            return "Draft";
        case "cancelled":
            return "Cancelled";
        case "inactive":
            return "Inactive";
        case "paused":
            return "Paused";
        case "hide":
            return "Hidden";
        default:
            return "N/A";
    }
});

Vue.filter("compressFile", (imageFile) => {
    let size = imageFile.size / 1024 / 1024; // in MiB
    if (size < 0.2) {
        return imageFile;
    }

    return new Promise((resolve, reject) => {
        let options = {
            maxSizeMB: 0.1,
            maxWidthOrHeight: 1920,
            useWebWorker: true,
        };
        imageCompression(imageFile, options)
            .then(function (compressedFile) {
                resolve(compressedFile);
            })
            .catch(function (error) {
                reject(error.message);
            });
    });
});

Vue.filter("isResolutionValid", (image) => {
    let reader = new FileReader();
    let minWidth = 600;
    let minHeight = 400;
    return new Promise((resolve, reject) => {
        reader.onload = (e) => {
            let img = new Image();
            img.src = e.target.result;

            img.onload = function () {
                if (this.width < minWidth || this.height < minHeight) {
                    resolve(false);
                } else resolve(true);
            };
        };
        reader.readAsDataURL(image);
    });
});
